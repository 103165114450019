<template>
  <div class="balance" v-loading="loading">
    <el-container>
      <el-aside>
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :current-node-key="currentNodeKey"
          :default-expanded-keys="defaultExpandedKeys"
          :default-expand-all="true"
          :highlight-current="true"
          @node-click="handleNodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ data.NodeName }}</span>
            <el-popover placement="bottom" trigger="hover">
              <ul class="balance-list">
                <li
                  v-if="data.NodeType == 1 || data.NodeType == 2"
                  @click="onAddClassify(data)"
                >
                  <i class="el-icon-plus"></i>
                  <span>添加{{ data.NodeType == 1 ? "分类" : "项目" }}</span>
                </li>
                <li
                  v-if="data.NodeType == 2 || data.NodeType == 3"
                  @click="onAlterClassify(data)"
                >
                  <i class="el-icon-edit"></i>
                  <span>修改{{ data.NodeType == 2 ? "分类" : "项目" }}</span>
                </li>
                <li
                  v-if="data.NodeType == 2 || data.NodeType == 3"
                  @click="onDeleteClassify(data)"
                >
                  <i class="el-icon-delete"></i>
                  <span>删除{{ data.NodeType == 2 ? "分类" : "项目" }}</span>
                </li>
              </ul>
              <span slot="reference" v-if="data.NodeGuid !== 'All'">
                <i
                  class="el-icon-more"
                  style="padding: 5px; color: #999999"
                ></i>
              </span>
            </el-popover>
          </span>
        </el-tree>
      </el-aside>
      <el-main>
        <div class="df-row">
          <div class="df-col">
            <el-date-picker
              v-model="dateArray"
              size="small"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div class="df-col">
            <el-select
              v-model="submitData.spend_state"
              placeholder="状态"
              size="small"
            >
              <el-option
                v-for="item in initConditionData.spendState"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df-col">
            <el-select
              v-model="submitData.spend_way"
              placeholder="途径"
              size="small"
            >
              <el-option
                v-for="item in initConditionData.spendWay"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df-col">
            <el-select
              v-model="submitData.empl_guid"
              placeholder="经手人"
              size="small"
            >
              <el-option
                v-for="item in initConditionData.emplList"
                :key="item.EmplGuid"
                :label="item.EmplName"
                :value="item.EmplGuid"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df-col">
            <el-input
              v-model="submitData.spend_note"
              placeholder="请输入内容"
              size="small"
            >
            </el-input>
          </div>
          <div class="df-col">
            <el-button
              @click="onSearchIncomexEpenditure"
              type="primary"
              size="small"
            >
              搜索
            </el-button>
          </div>
          <div class="df-col">
            <el-button @click="onClearSearch" type="primary" size="small">
              清空搜索
            </el-button>
          </div>
          <div class="df-col">
            <el-button
              @click="onAddIncomeExpenditure"
              type="primary"
              size="small"
            >
              新增收支
            </el-button>
          </div>
        </div>

        <el-table
          :data="tableData"
          :max-height="tableMaxHeight"
          border
          size="mini"
          style="width: 100%"
        >
          <el-table-column prop="BillNo" label="单号"> </el-table-column>
          <el-table-column prop="SpendTypeName" label="收支类型">
          </el-table-column>
          <el-table-column prop="KindName" label="收支分类"> </el-table-column>
          <el-table-column prop="ItemName" label="收支项目"> </el-table-column>
          <el-table-column prop="SpendWayName" label="收支途径">
          </el-table-column>
          <el-table-column prop="SpendMoney" label="收支金额">
          </el-table-column>
          <el-table-column prop="PaymentName" label="付款方式">
          </el-table-column>
          <el-table-column prop="Handler" label="经手人"> </el-table-column>
          <el-table-column prop="SoftwareDate" label="记账日期">
            <template slot-scope="scope">
              {{
                util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row.SoftwareDate * 1000)
                )
              }}
            </template>
          </el-table-column>
          <el-table-column prop="SpendStateName" label="是否删除">
          </el-table-column>
          <el-table-column prop="UpdateTime" label="最后更新">
            <template slot-scope="scope">
              {{
                util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row.UpdateTime * 1000)
                )
              }}
            </template>
          </el-table-column>
          <el-table-column prop="UpdateAdminName" label="最后操作">
          </el-table-column>
          <el-table-column prop="SpendNote" label="备注"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                class="df-button"
                @click="onAlterIncomeExpenditure(scope.row)"
                type="success"
                size="mini"
              >
                修改
              </el-button>
              <el-button
                class="df-button"
                type="danger"
                size="mini"
                @click="onDeleteIncomeExpenditure(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="amount">
          <span style="color:black;">本次共查询 {{ tableData.length }} 个记录</span>
          <!-- <span>合计：¥ {{ amountPrice }}</span> -->
          <span class="revenue">收入合计：¥ {{ amounts.revenueAmount }}</span>
          <span class="pay">支出合计：¥ {{ amounts.payAmount }}</span>
        </div>
      </el-main>
    </el-container>
    <AddClassify
      ref="AddClassify"
      @addClassifySuccess="querySpendTree"
    ></AddClassify>
    <AddItem ref="AddItem" @addItemSuccess="querySpendTree"> </AddItem>
    <AddIncomeExpenditure
      ref="AddIncomeExpenditure"
      @addIncomeExpenditureSuccess="initPageData"
    >
    </AddIncomeExpenditure>
  </div>
</template>

<script>
import api from "../../../api/cashier.js";
import AddIncomeExpenditure from "./components/add-income-expenditure.vue";
import AddClassify from "./components/add-classify.vue";
import AddItem from "./components/add-item.vue";
export default {
  components: { AddIncomeExpenditure, AddClassify, AddItem },

  data() {
    return {
      currentNodeKey: "ALL",
      defaultExpandedKeys: ["ALL"],
      treeData: [],
      defaultProps: {
        label: "NodeName",
        children: "Children",
      },
      more: "",
      tableMaxHeight: "",
      amounts: {
        revenueAmount: 0,
        payAmount: 0,
      },
      initConditionData: {},
      submitData: {
        begin_date: "",
        end_date: "",
        spend_way: 0,
        empl_guid: "",
        spend_note: "",
        item_guid: "",
        spend_state: 0,
      },
      dateArray: [],
      tableData: [],
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    this.dateArray = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.querySpendTree();
    this.queryCondition();
    this.initPageData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    async querySpendTree() {
      try {
        let { data } = await api.getSpendTree();
        this.treeData = data;
      } catch (e) {}
    },

    async queryCondition() {
      try {
        let { data } = await api.initQueryData();
        this.initConditionData = data;
      } catch (e) {}
    },

    async initPageData() {
      this.loading = true;
      try {
        this.submitData.begin_date =
          this.dateArray.length > 0
            ? this.dateArray[0]
            : this.util.getNowMonthToday();
        this.submitData.end_date =
          this.dateArray.length > 0
            ? this.dateArray[1]
            : this.util.getNowMonthToday();

        let { data } = await api.getSpendMaintList(this.submitData);
        let revenueAmount = 0,
          payAmount = 0;
        data.forEach((item) => {
          if (item.SpendType == 1) {
            payAmount += item.SpendMoney;
          } else {
            revenueAmount += item.SpendMoney;
          }
        });

        this.tableData = data;
        this.amounts = {
          revenueAmount,
          payAmount,
        };
      } catch (e) {}
      this.loading = false;
    },

    onSearchIncomexEpenditure() {
      this.initPageData();
    },

    onClearSearch() {
      this.submitData = {
        begin_date: "",
        end_date: "",
        spend_way: "",
        empl_guid: "",
        spend_note: "",
        item_guid: "",
        spend_state: "",
      };
    },

    handleNodeClick(event) {
      if (event.NodeType == 3) {
        this.submitData.item_guid = event.NodeGuid;
        this.initPageData();
      }
    },

    onAddClassify(event) {
      if (event.NodeType == 1) {
        this.$refs.AddClassify.openAddClassify(event);
      } else if (event.NodeType == 2) {
        this.$refs.AddItem.openAddItem(event);
      }
    },

    onAlterClassify(event) {
      if (event.NodeType == 2) {
        this.$refs.AddClassify.openAddClassify(event, event.NodeGuid);
      } else if (event.NodeType == 3) {
        this.$refs.AddItem.openAddItem(event, event.NodeGuid);
      }
    },

    onDeleteClassify(event) {
      try {
        if (event.NodeType == 2) {
          this.$confirm(`是否删除该‘${event.NodeName}’分类`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              let { data, errcode, errmsg } = await api.deleteSpendKind({
                kind_guid: event.NodeGuid,
              });
              if (errcode == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.querySpendTree();
              } else {
                this.$message.error("删除失败");
              }
            })
            .catch(() => {});
        } else if (event.NodeType == 3) {
          this.$confirm(`是否删除该‘${event.NodeName}’项目`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              let { data, errcode, errmsg } = await api.deleteSpendItem({
                item_guid: event.NodeGuid,
              });
              if (errcode == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.querySpendTree();
              } else {
                this.$message.error("删除失败");
              }
            })
            .catch(() => {});
        }
      } catch (e) {}
    },

    //
    onAddIncomeExpenditure() {
      this.$refs.AddIncomeExpenditure.openAddIncomeExpenditure();
    },

    onAlterIncomeExpenditure(event) {
      this.$refs.AddIncomeExpenditure.openAddIncomeExpenditure(event.SpendGuid);
    },

    onDeleteIncomeExpenditure(event) {
      this.$confirm(`是否删除该‘${event.BillNo}’账单`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let { data, errcode, errmsg } = await api.deleteSpend({
            spend_guid: event.SpendGuid,
          });
          if (errcode == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.initPageData();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.balance {
  width: 100%;
  height: 100%;

  .df-row {
    .df-col {
      .el-select {
        width: 120px;
      }

      .el-date-editor {
        width: 260px !important;
      }
    }
  }

  .el-container {
    width: 100%;
    height: 100%;
    .el-aside {
      height: 100%;
      width: 12% !important;
      border-right: 1px solid #d3dce6;
      .custom-tree-node {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    .el-main {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      .el-row {
        margin-bottom: 20px;
      }
      .el-date-editor {
        width: 100%;
      }
    }
  }

  .el-popover {
    width: 80px;
  }

  .df-button {
    margin: 5px 0 !important;
  }

  .el-main{
    position: relative;
  }

  .amount {
    .flex-row();
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40px;
    padding: 0 20px;
    box-sizing: border-box;
    color: limegreen;

    span{
      margin-right: 20px;
    }

    > span:last-child {
      color: red;
    }
  }
}
</style>
